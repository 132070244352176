<template>
  <v-card
    :style="smUp ? 'background-color: transparent; padding-top: 20px; margin-bottom: 50px':''"
    flat>
      <v-layout
        :style="smUp ? 'max-width: 85%; margin-top: 2.5%': 'margin-top: 2.5%; margin-left: 20%'"
        wrap
        row
        :justify-center="!smUp"
        :justify-start="smUp">
        <v-flex
          xs12
          sm6>
          <v-layout
            row
            :justify-center="!smUp"
            :justify-start="smUp">
            <p
              v-text="'Outages history'"
              class="view_title_text"
            />
          </v-layout>
        </v-flex>
        <!-- Start add with parameters(in case of necessary remove comments) -->
        <!-- 
        <v-flex
          xs12
          sm6>
          <v-layout
            row
            :justify-center="!smUp"
            :justify-end="smUp">
            <v-btn
              @click="add_outage = true"
              style="margin: 20px"
              color="primary"
              dense
              >
              Add
            </v-btn>
          </v-layout>
        </v-flex> 
        -->
        <!-- End add with parameters(in case of necessary remove comments) -->
      </v-layout>
      <v-layout
        v-if="total_outages > 0 && !bLoading"
        :style="smUp ? 'margin-left: -10%':''"
        row
        justify-center>
        <p  
          :class="smUp  ? 'total_user_text':'total_user_text_mobile'"
          v-text="'There is a total of ' + total_outages + '  outages. '"
        />
      </v-layout>
      <v-layout>
        <v-container>
          <!-- Start table container -->
          <div
            v-if="!bLoading">
            <outage-table 
              :items="outage_list"
              @update="get_outages"
            />
            <v-pagination 
              v-model="current_page" 
              :length="num_pages">
            </v-pagination>
          </div>
          <!-- Loading animation -->
          <v-card
            flat
            style="height: 100%; width: 100%; background-color: transparent"
            v-else>
            <v-layout
              :style="smUp ? 'max-width: 90%; margin-top: 10%':''"
              row
              justify-center>
              <v-progress-circular
                indeterminate
                rotate
                color="primary"
                size="100"
                width="1"
              />
            </v-layout>
          </v-card>
          <!-- Loading animation -->
          <!-- End table container -->
        </v-container>
      </v-layout>
      <outage-add
        :bActive="add_outage"
        @close="add_outage = false"
        @update="get_outages"
      />
  </v-card>
</template>
<script>
export default {
  data: () => ({
    current_page: 1,
    num_pages: 0,
    outage_list: [],
    add_outage: false,
    bLoading: false, 
    total_outages: 0
  }),
  watch: {
    current_page: function () {
      this.get_outages()
    }
  },
  mounted () {
   this.get_outages()
  },
  methods: {
    get_outages () {
      this.bLoading = true
      db.get(`${uri}/v2/api/outages`, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'Authorization': this.usr_token
        },
        params: {
          iPage: this.current_page,
          iPageSize: 15,
        }
      })
      .then(resp => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'success', text: resp.data.message})
        this.num_pages = resp.data.iTotalPages
        this.outage_list = resp.data.outages
        this.total_outages = resp.data.iTotalOutages
      })
      .catch(err => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'fail', text: err.response.data.message})
      })
    }
  },
  computed: {
    smUp () {
      return this.$vuetify.breakpoint.smAndUp
    },
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    }
  },
  components: {
    OutageAdd: () => import('@/components/sections/Admin/Outages/OutagesAdd'),
    OutageTable: () => import('@/components/sections/Admin/Outages/OutagesTable'),
  }
}
</script>